import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";

export const plans = {
  EXCLUSIVE: {
    value: "EXCLUSIVE",
    name: "Exclusive",
    price: 19,
    features: { guests: 80, emails: 160 },
  },
  TRENDY: {
    value: "TRENDY",
    name: "Trendy",
    price: 39,
    features: { guests: 260, emails: 780 },
  },
  GLAM: {
    value: "GLAM",
    name: "Glam",
    price: 79,
    features: { guests: 520, emails: 1560 },
  },
};

export const projectStatus = {
  PENDING: { color: "secondary", value: "PENDING" },
  ACTIVE: { color: "green", value: "ACTIVE" },
  ERROR: { color: "red", value: "ERROR" },
  BLOCKED: { color: "red", value: "BLOCKED" },
};

export const communicationStatus = {
  PENDING: { color: "yellow", value: "PENDING" },
  READY: { color: "primary", value: "READY" },
  // SENT IS DEPRECATED
  SENT: { color: "green", value: "SENT" },
  ERROR: { color: "red", value: "ERROR" },
  BLOCKED: { color: "red", value: "BLOCKED" },
};

export const messageSendStatus = {
  SCHEDULED: {
    color: "yellow",
    value: "SCHEDULED",
    label: "label-communication-status-pending",
    icon: ClockIcon,
  },
  SENT: {
    color: "green",
    value: "SENT",
    label: "label-communication-status-sent",
    icon: CheckCircleIcon,
  },
  ERROR: {
    color: "red",
    value: "ERROR",
    label: "label-communication-status-error",
    icon: ExclamationCircleIcon,
  },
};

export const roles = {
  OWNER: 9,
  GUEST: 0,
};

export const communicationChannels = {
  EMAIL: {
    value: "EMAIL",
    plans: [plans.EXCLUSIVE.value, plans.TRENDY.value, plans.GLAM.value],
  },
  WHATSAPP: {
    value: "WHATSAPP",
    plans: [plans.TRENDY.value, plans.GLAM.value],
    badge: "Beta",
  },
};

export const formatDate = (dates) => {
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  const startDate = new Date(dates[0]);

  if (dates.length === 1) {
    return dateFormatter.format(startDate);
  }

  const endDate = new Date(dates[1]);

  return dateFormatter.formatRange(startDate, endDate);
};

const emojiRegex =
  "[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]";

export const subjectRegex = new RegExp(
  ["\\*|_|~|```", emojiRegex].join("|"),
  "g",
);
export const signRegex = new RegExp(emojiRegex, "g");
